import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
const useStyles = makeStyles((theme) => {
  return {
    courseAccordion: {
      backgroundColor: theme.palette.secondary.light,
      border: "1px solid #E4E7EC",
      marginBottom: "10px",
      borderRadius: "4px",
    },
    courseAccordionSumm: {
      backgroundColor: theme.palette.primary.white,
    },
    wordFormat: {
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
  };
});
export default function CourseAccordion({ course }) {
  const classes = useStyles();
  return (
    <div>
      {course &&
        course.faq.map((item, i) => (
          <Accordion className={classes.courseAccordion} elevation={0} key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.courseAccordionSumm}>
              <Box>
                <pre className={classes.wordFormat}>{item.answer}</pre>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
