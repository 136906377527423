import React, { useState } from 'react'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import axios from "axios";
import { makeStyles } from "@mui/styles";
import Toast from '../Common/Snackbar'
import validator from 'validator'
import CircularProgress from '@mui/material/CircularProgress';
const useStyles = makeStyles((theme) => {
    return {
        subscribe_form:{
          position:"relative"
        },
        subscribe: {
            display: "flex",
        },
        subscribe_field: {
            marginRight: "15px",
            backgroundColor: theme.palette.primary.white,
            borderColor: theme.palette.primary.main,
            width: "100%",
        },
        subscribe_btn: {
            height: "100%",
            minHeight: "45px",
            width: "100%"
        },
        loader:{
            position: "absolute",
            left:"70%",
            top:"30%",
            [theme.breakpoints.down('md')]: {
                left:"60%",
              },
        }
    }
});
export default function Subscribe() {
    const classes = useStyles();
    const [subscribe, setsubscribe] = useState("");
    const [isOpen, setisOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading,setLoading] = useState(false);
    const handleInput = (event) => {
        setsubscribe(event.target.value)
    };


    const handleSubmit = ((e) => {
        e.preventDefault();
        setLoading(true)
        if (!subscribe) {
            setisOpen(true)
            setMessage("Enter valid input")
            setLoading(false)
        } else {
            if (validator.isEmail(subscribe)) {
                axios.post('https://api.holbos.com/subscriber', { email_id: subscribe })
                    .then(res => {
                        setisOpen(true)
                        setMessage(res.data.data)
                        setLoading(false)
                        setsubscribe("")
                    })
                    .catch(error => {
                        setisOpen(true)
                        setMessage("Server error try again later")
                        setLoading(false)
                        
                    })
               
            } else{
                setisOpen(true)
                setMessage("Enter valid email address")
                setLoading(false)
            }

        }

    })
    return (
        <>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} className={classes.subscribe_form}>


                <Grid container spacing={2}>
                    <Grid item xs={9} md={10}>
                        <TextField
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            color="primary"
                            className={classes.subscribe_field}
                            value={subscribe}
                            onChange={handleInput}
                        />

                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.subscribe_btn}

                        >
                            <ArrowForwardRoundedIcon />
                        </Button>
                    </Grid>
                </Grid>

               {loading?<CircularProgress size={20} color="inherit" thickness={1} className={classes.loader}/>:<Toast message={message} isOpen={isOpen} setisOpen={setisOpen} />} 

            </Box>

        </>
    )
}
