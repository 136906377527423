import React, { Suspense } from "react";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Loader from "../Components/Loader.js";
import "../assets/styles.scss";
import { makeStyles } from "@mui/styles";
import { Helmet } from 'react-helmet-async';
const About = React.lazy(() => import("../Components/About/About_2.js"));

const useStyles = makeStyles((theme) => {
  return {
    aboutPage: {
      padding: "20px 15px",
    },
    aboutTitle: {
      backgroundColor: theme.palette.third.main,
      padding: "20px",
      borderRadius: "5px",
      fontSize: "30px",
      textAlign: "center",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        padding: "20px",
        fontSize: "24px",
      },
    },
  };
});

export default function Aboutus() {
  const classes = useStyles();
  return (
    
    <StyledEngineProvider injectFirst>
  

  
      <div>
        <Suspense fallback={<Loader />}>
       
          <Helmet>
            <title>Holbos - About Us</title>
            <meta name="description" value="About Holbos STEM  Learning" />
          </Helmet>
       
          <div className={classes.aboutPage}>
            <div className={classes.aboutTitle}>
              <h4>About Us</h4>
            </div>
            <About />
          </div>
        </Suspense>
      </div>
    
    </StyledEngineProvider>
 
  );
}
