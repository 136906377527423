import React from 'react'
import { useParams } from "react-router-dom";
import '../assets/styles.scss'
import Success from '../Components/Common/success_logo';
import Failure from '../Components/Common/failure_logo';
export default function Holbos() {

    let {id,status,message } = useParams();

    return (
        <div className="custom_page">
            <div className="custom_container">
                <div>
                { id==='true'? (<Success />):(<Failure/>)}
                </div>
                <p className="custom_status">{status}</p>
                <p className="custom_message">{message}</p>
            </div>

        </div>
    )
}
