import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography'
import Button from "@mui/material/Button";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Link from '@mui/material/Link';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Box from "@mui/material/Box";
import Arrow from "../../assets/arrow.svg"
const useStyles = makeStyles((theme) => {
  return {
   
    quickContact_text: {
      color: "#fff",
      fontWeight:"500"
    },

    subscribe_btn: {
    background: 'linear-gradient(45deg, #ffffff 30%, #ffffff 90%)',
    border: 0,
    borderRadius: 3,
    color: theme.palette.primary.main,
    height: 48,
    padding: '0 30px',
    textTransform:'capitalize'
    },
    contact_section:{
      backgroundColor: theme.palette.primary.main,
      borderRadius: "5px",
      padding:"40px 30px",
      [theme.breakpoints.down('md')]: {
        padding:"40px 20px",
      },
     
    },
    arrow_img:{
      width:'150px',
      height:'70px',
      [theme.breakpoints.down('lg')]: {
        display:'none'
      },
    }

  };
});

export default function QuickContact(props) {
  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
    
      <Container
        className={classes.quickContact} maxWidth="lg" 
        sx={{
          paddingTop: { xs: "20px", lg: "28px" },
          paddingBottom: { xs: "20px", lg: "28px" },
        }}
      >
        <Grid container className={classes.contact_section} justifyContent="center"
  alignItems="center" >
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={{textAlign:{xs:'center', md:'left'},marginBottom:{xs:'20px', md:'0px'}}}>
            <Typography variant="h6" className={classes.quickContact_text}>
             {props.title}
            </Typography>
            </Box>
          
          </Grid>

          <Grid item lg={4}>
            <Box sx={{textAlign:'center'}}>
            <img src={Arrow} alt="arrow" className={classes.arrow_img}/>
            </Box>
           
          </Grid>
      
          <Grid item xs={12} md={6} lg={4} >
            <Box sx={{textAlign:{xs:'center', md:'right'}}}>
            <Button
              component={Link} href="/contact"
              className={classes.subscribe_btn}
              endIcon={<ArrowForwardRoundedIcon />}
              >
              Ask your Questions
            </Button>
            </Box>
           
          </Grid>
        </Grid>
      </Container>

    </StyledEngineProvider>
  );
}
