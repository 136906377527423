import React from "react";
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import NavItem from "./NavItems";


const useStyles = makeStyles((theme) => {
  return {
    header: {
      backgroundColor: "#fff",
      width: "100%",
    },
    menuButton: {
      color: "#000",
      marginRight: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar

  };
});

export default function Header(props) {
  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <React.Fragment>
        <CssBaseline />

        <AppBar className={classes.header} elevation={3}>
          <Toolbar className={classes.toolbar}>
            <NavItem />
          </Toolbar>
        </AppBar>

        <Toolbar />
      </React.Fragment>
    </StyledEngineProvider>
  );
}
