import React from 'react'
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
const useStyles = makeStyles((theme) => {
  return {
    
    description: {
      color: theme.palette.text.primary,
      fontWeight: '400'
    },
    wordFormat:{
      whiteSpace:"pre-wrap",
      wordBreak: "break-word"
    }

  };
});
export default function CurriculumHeader(props) {
  const { title, desc } = { ...props }
  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <Container maxWidth="md"
        sx={{
          paddingTop: { xs: "20px", md: "20px", },
          paddingBottom: { xs: "20px", md: "35px" },
          paddingLeft: { xs: "25px", md: "20px" },
          paddingRight: { xs: "25px",md: "20px" },
        }}>

        <Box sx={{ textAlign: { xs: 'center' } }}>
          <Typography variant="h1" className={classes.title} sx={{ fontSize: { xs: '24px', md: '32px' }, marginBottom: { xs: '10px', lg: '15px' }, fontWeight:{xs:'500'} }}>

            {title}
          </Typography>
          <pre className={classes.wordFormat}>
          <Typography variant="body1" className={classes.description} sx={{ fontSize: { xs: '16px', sm: '18px' } }}>
           
            {desc}
           
          </Typography>
          </pre>
        </Box>

      </Container>
    </StyledEngineProvider>
  )
}
