import { React } from "react";
import { makeStyles } from "@mui/styles";
//import TextContent from "../Components/Content/TextContent";
import QuickContact from "../Components/Contact/QuickContact";

// const Terms = [
//   {
//     title: "1.Why do we use it",
//     desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
//   },
//   {
//     title: "1.Why do we use it",
//     desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. ",
//   },
// ];

const useStyles = makeStyles((theme) => {
  return {
    curriculumdetail: {
      display: "flex",
      flexDirection: "column",
      padding: "15px",
    },
    pageTitleContainer: {
      padding: "30px 20px",
      textAlign: "center",
      backgroundColor: "#EDEEEE",
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px",
      },
    },
    pageTitle: {
      fontSize: "32px",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
    },
    pageTitledesc: {
      textAlign: "left",
      padding: "20px",
      paddingTop: "3rem",
      paddingBottom: "3rem",
      width: "80%",
      margin: "0 auto",
      fontSize: "18px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px",
        width: "100%",
        fontSize: "16px",
      },
    },
    pageContent: {
      width: "80%",
      margin: "0 auto",
      padding: "20px",
      [theme.breakpoints.down("md")]: {
        padding: "15px",
        width: "100%",
      },
    },
    pageContent_title: {
      fontSize: "1.5rem",
      fontWeight: "500",
      marginBottom: "0.8rem",

      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
    pageContent_desc: {
      fontSize: "1rem",
      marginBottom: "1.2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
      },
    },
  };
});
export default function TermsandConditions() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageTitleContainer}>
        <h1 className={classes.pageTitle}>Terms and Conditions</h1>
      </div>
      <div className={classes.curriculumdetail}>
        <div className={classes.pageTitledesc}>
          Holbos, a pioneering company in the field of STEM learning blending a
          unique model of personalized curriculum for our users, makes sure we
          are bringing in a world class experience in learning STEM. We collect
          course fees based on the level of curriculum the customer purchases
          and all charges are clearly explained to our customers. In case of any
          course changes or planning to cancel the course both should be
          communicated to the respective tutors before reaching us. We humbly
          request all our users to follow this rule to make our process more
          linear.
          <br />
          <br />
          The refund policy has a general rule of thumb, where you pay only for
          what you have given. The rest will be refunded from our end without
          any delays. In case of upgrading the course levels or shifting to
          another course domain the charges cannot be the same. We appreciate
          your cooperation when it comes to these changes. Any policy changes or
          course upgrades will be notified to our customers after we announce it
          officially. Any change of plans for upgrading courses would then be
          charged based on the new course fee structure.
          <br />
          <br />
          Any complaints regarding classes can be communicated via mail to make
          sure you are reaching the appropriate channels to resolve the issues
          faster. We are not responsible for delay in case of reaching the wrong
          channels for your queries. We look forward to serving you the best
          from our end.
        </div>

        {/* {Terms.map((item) => (
      <TextContent title={item.title} desc={item.desc} />
    ))} */}

        <QuickContact title="Any question about our terms" />
      </div>
    </>
  );
}
