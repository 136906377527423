import axios from "axios";
import {React,useState,useEffect} from 'react'
import {Route,Routes } from "react-router-dom";
import Quiz from './Quiz'
import QuizMain from './QuizMain'
import Result from './Result'

export default function BookCourse() {
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [questions,setQuestions] = useState("")
    const [score,setScore] = useState(0);
    const [courseList,setcourseList] = useState(false);
    const [course,setCourse] = useState("");
    const fetchQuestion = async(category)=>{
      const {data} = await axios.get(`https://api.holbos.com/course-question?course_id=${category}`);
      setQuestions(data.data);
    }
    
    useEffect(() => {
      
        const fetchCourseList = async () => {
          const { data } = await axios.get(`https://api.holbos.com/course?fields=course_id%2Ccourse_title`);
          setcourseList(data.data)
        
        }

        fetchCourseList()
    
      }, [])
      
    return (

    
            <Routes>
                <Route exact path="/" element={<Quiz name={name} setName={setName} fetchQuestion ={fetchQuestion} courseList={courseList} setCourse={setCourse} email={email} setEmail={setEmail}/>}/>
                    
             
                <Route path="/quizmain" element={<QuizMain name={name} questions={questions} score={score} setScore={setScore} setQuestions={setQuestions} course={course} email={email}/>} />
                    
             
                <Route  path="/result" element={<Result score={score} name={name}/>}/>
                    
               
            </Routes>
    

    )
}
