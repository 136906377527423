import React from 'react'
import Container from '@mui/material/Container'
import PricingCard from './PricingCard';
import Grid from "@mui/material/Grid";
// const course = [
//     {
//       img: "../Images/android_logo.svg",
//       title: "Android Development",
//       buttonLink: "/coursedetail",
//     },
//     {
//         img: "../Images/python.png",
//         title: "Python Programming",
//         buttonLink: "/coursedetail",
//     },
//     {
//         img: "../Images/android_logo.svg",
//       title: "Android Development",
//       buttonLink: "/coursedetail",
//     },
//     {
//         img: "../Images/python.png",
//         title: "Python Programming",
//         buttonLink: "/coursedetail",
//     },
//     {
//         img: "../Images/android_logo.svg",
//       title: "Android Development",
//       buttonLink: "/coursedetail",
//     },
//     {
//         img: "../Images/python.png",
//         title: "Python Programming",
//         buttonLink: "/coursedetail",
//     },
//   ];

export default function CurriculumCards({courses}) {
  
  
    return (
       <Container maxWidth="lg" sx={{
        paddingLeft: { xs: "25px", md: "15px" },
        paddingRight: { xs: "25px",md: "15px"}
      }}>
           <Grid container   spacing={3} justifyContent="center" alignItems="center">
              
              {
                  courses && courses.map((item,i)=>(
                     
                      
                     <PricingCard key={i} data={item}/>
                   
                    
                   ))  
              }
            
              
              
          </Grid>
       </Container>
    )
}
