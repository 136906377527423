import React, { useState } from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import validator from 'validator'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
const useStyles = makeStyles((theme) => {
    return {
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding:'25px 20px',
            [theme.breakpoints.down('md')]: {
                padding: '20px 10px',
            },

        },
        formsection: {
            display: 'flex',
            flexDirection: 'column',
            margin: '30px 0',
            [theme.breakpoints.down('md')]: {
                margin: '20px 0',
            },
            backgroundColor: theme.palette.primary.white,
            borderRadius: '4px',
            boxShadow: 'rgb(248 239 255 / 24%) 0px 0px 2px 0px, rgb(248 239 255 / 24%) 0px 16px 32px -4px',
            padding: '20px 24px'
        },
        root: {
            "& .MuiOutlinedInput-root": {
                background: theme.palette.primary.white,

            },
            marginTop: '1rem',
            marginBottom: '1rem',
            [theme.breakpoints.down('md')]: {
                marginBottom: '.5rem',
            },
            "& .MuiFormLabel-root": {
                color: "rgb(145, 158, 171)"
            },
            "& .Mui-focused": {
                color: theme.palette.text.primary
            }
        },
        loader: {
            marginBottom: "5px"
        }


    };
});



export default function ContactForm() {
    const classes = useStyles()
    const [username, setUsername] = useState(false)
    const [useremail, setUseremail] = useState(false)
    const [usersubject, setUsersubject] = useState(false)
    const [usermessage, setUsermessage] = useState(false)
    const [alertMsg, setalertMsg] = useState("")
    const [status, setStatus] = useState("")
    const [loading, setLoading] = useState(false);
    const [submit,setSubmit] = useState(false)
    const [FormData, setFormdata] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""

    })
    const { name, email, subject, message } = FormData;

    const handleName = ((e) => {

        setFormdata({ ...FormData, name: e.target.value })
        setUsername(false)
    })

    const handleEmail = ((e) => {

        setFormdata({ ...FormData, email: e.target.value })
        setUseremail(false)
    })
    const handleSubject = ((e) => {

        setFormdata({ ...FormData, subject: e.target.value })
        setUsersubject(false)
    })
    const handleMessage = ((e) => {

        setFormdata({ ...FormData, message: e.target.value })
        setUsermessage(false)
    })

    const handleSubmit = ((e) => {
        e.preventDefault()
        setLoading(true)

        // if (!FormData.name || !FormData.email || !FormData.subject || !FormData.message) {
        //     setStatus("error")
        //     setalertMsg("Please fill all input fields")
        //     setSubmit(true)
        //     setLoading(false)
        //    } 

           if (!FormData.name ) {
            setStatus("error")
            //setalertMsg("Please fill all input fields")
            setUsername(true)
            setLoading(false)
           }


           if (!FormData.subject) {
            setStatus("error")
            //setalertMsg("Please fill all input fields")
            setUsersubject(true)
            setLoading(false)
           } 

           if (!FormData.message) {
            setStatus("error")
            //setalertMsg("Please fill all input fields")
            setUsermessage(true)
            setLoading(false)
           } 

           if (!FormData.email) {
            setStatus("error")
            //setSubmit(true)
            setUseremail(true)
            setLoading(false)
           } 
             console.log(FormData)
             //console.log(!username,!usersubject,!usermessage,!useremail)
            if(FormData.name !=="" && FormData.email !=="" && FormData.subject !=="" & FormData.message !==""){
                if (validator.isEmail(FormData.email)) {

                    axios.post("https://api.holbos.com/contact-message", { user_name: name, email: email, subject: subject, message: message })
                        .then((res) => {
    
                            if (res.status === 200) {
                                if (res.data.status) {
                                    setStatus("success")
                                    setSubmit(true)
                                    setalertMsg(res.data.data)
                                    setLoading(false)
                                    setFormdata({ name:"", email:"", subject:"", message:"" })
                                    setTimeout(() => setSubmit(false), 5000);
                                   
                                    
                                } else {
                                    setStatus("error")
                                    setSubmit(true)
                                    setalertMsg(res.data.data)
                                    setLoading(false)
    
                                }
                            } else {
                                setStatus("error")
                                setSubmit(true)
                                setalertMsg("Unable to reach server")
                                setLoading(false)
    
                            }
    
                        })
                        .catch((error) => {
                            setStatus("error")
                            setalertMsg("Unable to reach server")
                            setSubmit(true)
                            setLoading(false)
    
                        })
                } else {
    
                    //setStatus("error")
                    //setalertMsg("Please Enter Valid Email Address")
                    setUseremail(true)
                    setLoading(false)
    
                }
            }
           
           
        
       

    })
    return (

        <StyledEngineProvider injectFirst>
            <div className={classes.formContainer}>
                <Box >
                <Typography
                  variant="h5"
                  color="text.primary"
                  className={classes.title}
                  sx={{
                    fontSize: { xs: '25px', md: '30px', lg: '30px'},
                    fontWeight: "600",
                    textAlign: "center",
                    marginBottom: "16px",
                  }}
                >
                  We’re better together!
                </Typography>
                <Typography
                  variant="h6"
                  color="text.primary"
                  className={classes.title}
                  sx={{
                    fontSize: { xs: "16px", md: "18px", lg: "18px" },
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "24px",
                  }}
                >
                  We are passionate about guiding your kids to learn robotics
                  and making human life easier and the world a better place!
                  </Typography>
                {/* </Typography>
                    <Typography variant="h4" color="text.primary" className={classes.title} sx={{ fontSize: { xs: '25px', md: '30px', lg: '30px' }, fontWeight: '600', textAlign: { xs: 'center', md: 'left' } }}>
                        Feel free to contact us.

                        We'll be glad to hear from you.
                    </Typography> */}
                </Box>

                <div className={classes.formsection}>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant="h1" color="text.primary" className={classes.title} sx={{ fontSize: { xs: '24px', md: '30px', lg: '30px' }, fontWeight: '600', textAlign: 'center', marginBottom: '16px' }}>
                            Contact Us
                        </Typography>

                    </Box>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Box sx={{ '& > :not(style)': { margin: '0 0 24px' }, }}>
                            <TextField error={(username)} id="outlined-basic" label="Name" value={FormData.name} variant="outlined" className={classes.root} fullWidth required onChange={handleName} helperText={(username) ? "Please Enter Valid Name" : ""} />
                        </Box>
                        <Box sx={{ '& > :not(style)': { margin: '0 0 24px' }, }}>
                            <TextField error={(useremail)} id="outlined-basic" label="Email" value={FormData.email} variant="outlined" className={classes.root} fullWidth required onChange={handleEmail} helperText={(useremail) ? "Please Enter Valid Email" : ""} />
                        </Box>
                        <Box sx={{ '& > :not(style)': { margin: '0 0 24px' }, }}>
                            <TextField error={(usersubject )} id="outlined-basic" label="Subject" value={FormData.subject} variant="outlined" className={classes.root} fullWidth required   onChange={handleSubject} helperText={(usersubject) ? "Please Enter Valid Subject" : ""} />
                        </Box>
                        <Box sx={{ '& > :not(style)': { margin: '0 0 24px' }, }}>
                            <TextField error={(usermessage)} id="outlined-basic" label="Message" value={FormData.message} variant="outlined" className={classes.root} fullWidth required  multiline minRows={4} maxRows={4} onChange={handleMessage} helperText={(usermessage) ? "Please Enter Valid Message" : ""} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', margin: '0 0 15px 0' }}>
                            <Button type="submit" variant="contained" sx={{ padding: '10px 16px' }} endIcon={<SendIcon />}>
                                Send Now
                            </Button>
                        </Box>
                        {submit ? <Alert severity={status} sx={{ marginBottom: '20px' }}>{alertMsg}</Alert> : ""}
                        {loading ? <CircularProgress size={40} color="inherit" thickness={1} className={classes.loader} /> : ""}
                    </Box>
                </div>

            </div>
        </StyledEngineProvider>

    )
}
