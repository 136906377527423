import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/logo.png";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import { useLocation } from "react-router-dom";
import clsx from "clsx";
//import BlogLink from "../Common/BlogLink";

const useStyles = makeStyles((theme) => {
  return {
    menuButton: {
      color: theme.palette.text.primary,
      marginRight: theme.spacing(2),
    },
    drawer: {
      width: "250px",
    },
    navIcon: {
      color: "rgb(99, 115, 129)",
    },
    navLink: {
      color: "rgb(99, 115, 129)",
      fontFamily: "Inter,sans-serif",
      fontSize: "14px",
      fontWeight: "500",
      display: "flex",
      width: "100%",
      padding: "5px 0",
    },
    logo: {
      width: "150px",
      height: "auto",
    },
    logoDrawer: {
      padding: "10px 10px 0px 10px",
    },
    active: {
      color: theme.palette.primary.main,
    },
  };
});

export default function DrawerComponent(props) {
  const classes = useStyles();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerItems = [
    {
      text: "Home",
      path: "/",
      icon: <HomeRoundedIcon className={classes.navIcon} />,
    },
    {
      text: "About Us",
      path: "/about",
      icon: <PersonRoundedIcon className={classes.navIcon} />,
    },
    {
      text: "Courses",
      path: "/courses",
      icon: <MenuBookRoundedIcon className={classes.navIcon} />,
    },
    {
      text: " Testimonials",
      path: "/testimonials",
      icon: <ChatRoundedIcon className={classes.navIcon} />,
    },
    {
      text: "Contact",
      path: "/contact",
      icon: <ContactSupportRoundedIcon className={classes.navIcon} />,
    },
    {
      text: " Book a Free Trial",
      path: "/registration",
      icon: <AssignmentTurnedInRoundedIcon className={classes.navIcon} />,
    },
   
  ];
  return (
    <>
      <Drawer
        variant="temporary"
        anchor="left"
        open={openDrawer}
        onClick={() => setOpenDrawer(false)}
      >
        <div className={classes.logoDrawer}>
          <img src={logo} alt="Holbos" className={classes.logo} />
        </div>

        <List className={classes.drawer}>
          {drawerItems.map((item, index) => (
            <ListItemButton onClick={() => setOpenDrawer(false)} key={index}>
              <Link
                reloadDocument
                to={item.path}
                className={
                  location.pathname === item.path
                    ? clsx(classes.navLink, classes.active)
                    : classes.navLink
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <div>{item.text}</div>
              </Link>
            </ListItemButton>
          ))}
          <ListItemButton onClick={() => setOpenDrawer(false)}>
            <Link className={clsx(classes.navLink)} href="https://blog.holbos.com" underline="none" onClick={(e) => { e.preventDefault(); window.open('https://blog.holbos.com', '_blank'); }}>
            <ListItemIcon><NewspaperIcon className={classes.navIcon} /></ListItemIcon>
            <div>Blogs</div>
            </Link>
            {/* <BlogLink /> */}
          </ListItemButton>
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        // onClick={toggleDrawer}
        className={classes.menuButton}
      >
        <MenuRoundedIcon />
      </IconButton>
    </>
  );
}
