import React from 'react'
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Typography from '@mui/material/Typography'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Link from '@mui/material/Link';
const useStyles = makeStyles((theme) => {
  return {
    pricingcard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px',
      borderRadius: '15px',
      color: '#343D48',
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      maxWidth: '480px',
      margin: 'auto',
      boxShadow: '0 18px 36px 0 rgb(213 215 225 / 49%)',
      transition: 'transform 1s',
      "&:hover": {
          transform:'scale(1.1)',
      },
      [theme.breakpoints.down('md')]: {
        "&:hover": {
          transform:'scale(1)',
      }
      },
    },
    card: {
      padding: '15px 15px 0px 15px',
      color: '#0f2137',
      width: '100%'
    },
    card_title: {
      fontSize: '18px'
    },
  };
});

export default function PricingCard({ data }) {
  const classes = useStyles();

  return (

    <StyledEngineProvider injectFirst>
      <Grid item xs={12} sm={6} md={4} lg={3} >
        <Link href={`/courses/${data.course_url_path}`} underline="none">
          <Card className={classes.pricingcard}>
            <CardMedia component="img" height="" image={data.course_image_url} alt={data.course_title} />
            <CardContent className={classes.card} sx={{ padding: '15px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  fontWeight="600"
                  className={classes.card_title}
                >
                  {data.course_title}
                </Typography>

              </Box>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </StyledEngineProvider>


  )
}
