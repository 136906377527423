import React from 'react'
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => {
  return {
   
    inner_banner: {
      backgroundColor: theme.palette.secondary.main,
      padding:'20px',
      borderRadius:'10px',
      [theme.breakpoints.down('md')]: {
        padding:'15px',
      },
    },
    banner_text: {
     
      fontWeight: '600'
    },
    banner_img: {
      width: '300px',
      [theme.breakpoints.down('md')]: {
        width:"150px",
      },
    }

  };
});
export default function InnerBanner(props) {

  const {title,image} = {...props}
  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <Container maxWidth="xl"
        sx={{
          paddingTop: { xs: "10px",md:"15px",},
          paddingBottom: { xs: "10px",md:"15px"},
          paddingLeft:{xs:"15px"},
          paddingRight:{xs:"15px"},
        }}
      >
        <Grid container className={classes.inner_banner} justifyContent="center"
          alignItems="center" >
          <Grid item xs={12} sm={9} lg={9}>
            <Box sx={{ textAlign: { xs: 'center', sm: 'left' }, marginBottom: { xs: '20px', sm: '0px' }}}>
              <Typography variant="h6" className={classes.banner_text} sx={{fontSize:{xs:'24px',sm:'24px',md:'32px'} }}>
                {/* Our Pre-ready Curriculum Packages */}
                {title}
              </Typography>
            </Box>

          </Grid>

          <Grid item xs={12} sm={3} lg={3}>
            <Box sx={{ textAlign: 'center' }}>
              <img src={image} alt="inner banner" className={classes.banner_img} />
            </Box>

          </Grid>
        </Grid>
      </Container>
    </StyledEngineProvider>
  )
}
