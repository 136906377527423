import {React} from 'react'
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Typography from '@mui/material/Typography'
import Trophy from "../assets/trophy.png"
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
const useStyles = makeStyles((theme) => {
    return {
        result_page:{
            backgroundColor:'#f4f5fa',
            display:'flex',
            minHeight:'90vh'
        },
        result_container:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        },
        result_title:{
            fontSize:'2.125rem',
            [theme.breakpoints.down('md')]: {
                fontSize:'2rem',
              },
              
        },
        tropy_wrapper:{
            display:'flex',
            justifyContent:'center',
            marginTop:'2rem',
            marginBottom:'2rem',
        },
        trophy_img:{
            width:'100px'
        }
    }
  
    }
  );
  

export default function Result({name,score}) {
    let badge = "";
    if(score >= 6){
        badge="Master"
    } else if (score >= 3 ){
        badge="Intermediate"
    } else {
        badge="Beginner"
    }
    const classes = useStyles();
    return (
        <StyledEngineProvider injectFirst>
        <div className={classes.result_page}>
        <Container  maxWidth="lg" className={classes.result_container} sx={{paddingTop: { xs: "20px", md: "30px", lg: "40px" },paddingBottom: { xs: "20px", md: "30px", lg: "40px" }}}>
           <Typography variant="h4"  textAlign="center" className={classes.result_title}> Congratulations {name}!  </Typography>
           <div className={classes.tropy_wrapper}>
               <img src={Trophy} alt="trophy" className={classes.trophy_img}/>
           </div>

           <Typography variant="h5"  textAlign="center">Your Level is {badge} </Typography>

           <Box sx={{display:'flex',justifyContent:'center',marginTop:'2rem'}}>
              <Button variant="contained" href="/registration/1">Book Your Course</Button>
           </Box>
           <Box sx={{display:'flex',justifyContent:'center',marginTop:'2rem'}}>
              <Button variant="contained" href="/course">Explore Courses</Button>
           </Box>
           
           
        </Container>
        </div>
        </StyledEngineProvider>
    )
}
