import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#8d448b',
      light:'#F8F5FB',
      light2:'#F8EFFF',
      white:'#ffffff'
    },
    secondary: {
      main: '#E4E7EC',
      light:'#F8F5FB',
      dark: '#0f2137'

    },
    third:{
      main:'#F6F8FB'
    },
    text: {
      primary: '#0f2137',
      secondary: '#8d448b',
      third:'#2D2D2D',
      white:'#ffffff',
      color1:'#343D48',
      grey:'#4F5C6D'
    },
  },
  typography: {
    fontFamily:[ 'Roboto','sans-serif'].join(',')
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  }
  })

  export default theme