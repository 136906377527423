import {React,useState} from 'react'
import '../../assets/styles.scss'
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from "axios";
import "../../assets/styles.scss"
const useStyles = makeStyles((theme) => {
    return {

        quizform: {
            backgroundColor: theme.palette.primary.white,
            boxShadow: '0 10px 30px rgb(16 30 54 / 10%)',
            padding: '40px 25px',
            position: 'relative',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width:'70%',
            margin:'20px auto',
            [theme.breakpoints.down('md')]: {
                width:"90%",
                
              },
        },
        singleQuestion:{
            textAlign:'center'
        },
        answer:{
            "&:hover":{
                backgroundColor: '#8d448b',
                color: '#fff',
            }
        }

    };
});
export default function Questions({ currentQues,
    setCurrentQues,
    questions,
    options,
    correct,
    score,
    setScore,
    setQuestions,name,course,email }) {
        const classes = useStyles()
        const [selected, setselected] = useState();
        const [error, seterror] = useState(false);
        const navigate = useNavigate();
        const handleSelect = ((i)=>{
           
            if(selected===i ){
                return "select";
            } 
        })

        const handleCheck=(i)=>{
            setselected(i);
           
            if(i===correct) setScore(score+1)
            seterror(false)
        }

        const handleNext = ()=>{
            
            if(currentQues >= 4){
                navigate.push('/result')
               
                axios.post('https://api.holbos.com/quiz-score', { score_user_full_name: name,score_user_email_id:email,score_course_id:course,score_course_name:"abc",score_value:score })
                .then((res)=>(
                   console.log(res)
                ))
                .catch((err)=>(
                    console.log(err)
                ))
            } else if (selected) {
                setCurrentQues(currentQues + 1)
                setselected()
            } else {
                seterror(true)
                
            }
        }
    
    return (
        <div className={classes.quizform}>
           <Box sx={{textAlign:'center',fontSize:{xs:'20px',md:'24px'}}}>
               <Typography variant="p" color="primary">Question {currentQues + 1}</Typography>
            </Box> 
            <div className={classes.singleQuestion}>
                <h2 className="question">{questions[currentQues].question}</h2>
            </div>
            <div className="option">
                {error && <p>Error Message</p>}
                <Grid container spacing={2}>
                 
                {options && options.map((i)=>
                <Grid item key={i} xs={12} md={6} >
                    <Button variant="outlined"  onClick={()=>{handleCheck(i)}} className={`singleOption ${selected && handleSelect(i)}`} >{i}</Button>
                </Grid>
                )}
                </Grid>
            </div>
            <div className="controls">
                <Button className="nextButton" variant="contained" onClick={handleNext} >Next</Button>
            </div>
        </div>
    )
}
