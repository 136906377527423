import {React,useState} from 'react'
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container'
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography'
import QuizImg from '../assets/win.svg'
import Alert from '@mui/material/Alert';
import validator from 'validator' 

const useStyles = makeStyles((theme) => {
    return {

        quiz_container: {
            backgroundColor: theme.palette.primary.light,
            padding:'40px 0px'
        },

     
        quizform: {
            backgroundColor: theme.palette.primary.white,
            boxShadow: '0 10px 30px rgb(16 30 54 / 10%)',
            padding: '60px 20px',
            position: 'relative',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width:'70%',
            [theme.breakpoints.down('sm')]: {
                width:'100%',
                padding: '25px 20px',
              },
        },
        formlabel: {
            display: 'block',
            marginBottom: '5px',
            fontFamily: ['DM Sans', 'sans-serif'].join(','),
            color: theme.palette.text.primary,
            fontWeight: '500'
        },
        quiz_img:{
            width:'100px'
        }
        
    };
});

// const Domain = [
   
//     {
//         course_id: '9',
//         course_title: 'Android Development',
//     },
//     {
//         course_id: '10',
//         course_title: 'Python Programming',
//     },
//     {
//         course_id: '11',
//         course_title: 'Robotics',
//     },
//     {
//         course_id: '12',
//         course_title: 'Electronics',
//     },
    
// ];


export default function Quiz({name,setName,fetchQuestion,courseList,setCourse,email,setEmail}) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [category,setCategory] = useState("");
    const [error,setError] = useState(false);
    const [alertMsg, setalertMsg] = useState("")


    const handleCategory = (event) => {
        setCategory(event.target.value);
    };  
     
    const handleName = (event) => {
        setName(event.target.value);
    }; 
    const handleEmail = (event) => {
        setEmail(event.target.value);
    }; 

    const handleSubmit = ((e)=>{
        e.preventDefault()
        
        if(!category || !name || !email){
            setError(true);
            setalertMsg("Please fill all input fields")
            return
        } else{
            if (validator.isEmail(email)){
                setError(false);
                fetchQuestion(category);
                setCourse(category)
                navigate('/quizmain')
                
            } else{
                setError(true);
                setalertMsg("Please enter a valid email address")
            }
           
        }
    })

    
    return (
        <StyledEngineProvider injectFirst>
            <div className={classes.quiz_container}>
             
                <Container maxWidth="lg" sx={{display:'flex',justifyContent:'center'}}>
                    <div className={classes.quizform}>
                        
                        <Box sx={{ textAlign: 'center', }}>
                           <Typography variant="h5" sx={{marginBottom: '20px'}}>Course Recommendation Quiz</Typography> 
                            <img src={QuizImg} alt="Quiz" className={classes.quiz_img}/>
                        </Box>
                        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                           {error &&   <Alert severity="error" sx={{width:{xs:'100%',md:'50%'},marginBottom:'15px',marginTop:'15px'}}>{alertMsg}</Alert>}
                            <Box sx={{ width:{xs:'100%',md:'50%'}, marginBottom: '12px' }}>
                                <label className={classes.formlabel}>Name</label>
                                <TextField id="outlined-basic" placeholder="Enter Your FullName" variant="outlined" fullWidth size="small" onChange={handleName}/>
                            </Box>
                            <Box sx={{ width:{xs:'100%',md:'50%'}, marginBottom: '12px' }}>
                                <label className={classes.formlabel}>Email</label>
                                <TextField type="email" id="outlined-basic" placeholder="Enter Your Email" variant="outlined" fullWidth size="small" onChange={handleEmail}/>
                            </Box>
                          
                            <Box sx={{ width:{xs:'100%',md:'50%'}, marginBottom: '12px' }}>
                            <label className={classes.formlabel}>Select Your Course</label>
                            <TextField
                                variant="outlined"
                                select
                             
                                value={category}
                                onChange={handleCategory}
                                
                                className={classes.root} fullWidth size="small">
                                {courseList && courseList.map((option,i) => (
                                    <MenuItem key={i} value={option.course_id}>
                                        {option.course_title}
                                    </MenuItem>
                                ))}
                            </TextField>
                            </Box>
                           <Box sx={{ width: '50%', marginTop: '20px' }}>
                          <Button   type="submit"  variant="contained" className={classes.submitButton} fullWidth >
                                    Start Quiz
                            </Button>
                           </Box>

                        </Box>


                    </div>
                </Container>
            </div>
        </StyledEngineProvider>
    )
}
