import { React, useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import InnerBanner from "../Components/Common/InnerBanner";
import '../assets/styles.scss';
import banner from "../assets/cirriculum_banner.svg"
import CurriculumHeader from '../Components/Curriculum/CurriculumHeader';
import CurriculumCards from '../Components/Curriculum/CurriculumCards';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';
import {
    useLocation
  } from "react-router-dom";
const useStyles = makeStyles((theme) => {
    return {

        curriculum: {
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom:'35px',
            [theme.breakpoints.down('md')]: {
                paddingBottom:'20px',
              },  

        },
        loader:{
            width:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            
        }
      
    }
})



export default function Course() {
    const classes = useStyles()
    const [courses, setCourses] = useState("");
    const [detail, setDetail] = useState("");
    const [loading, setLoading] = useState(false);

    // let { userId } = useParams();
    const location = useLocation()
    

     let pageUrl = location.pathname.split('/')[2];
     let pageTitle = pageUrl.replaceAll("%20"," ");
    useEffect(() => { 
        setLoading(true)
        const fetchCourses = async () => {
            // const { data } = await axios.get(`https://api.holbos.com/course`);
              const { data } = await axios.get(`https://api.holbos.com/domain-course-list?domain_name=${pageTitle}`);
                setCourses(data.data)
                setDetail(data.domain_desc_long)
                setLoading(false)
             }
        fetchCourses()

    }, [pageTitle])

    //console.log(courses[0].course_metadata_title);
    
    return (
        <StyledEngineProvider injectFirst>
            <Helmet>
            <title>{courses?courses[0].course_metadata_title:"Holbos"}</title>
            <meta name='description' content={courses?courses[0].course_metadata_desc:"Holbos"} />
          </Helmet>
        <div className={classes.curriculum}>
            <InnerBanner title={"Our Top-Class Courses"} image={banner} />
            <CurriculumHeader title={`Welcome to ${pageTitle} course gallery`} desc={detail} />
            {loading?(<Box className={classes.loader}><CircularProgress size={100} color="inherit" thickness={1} /></Box>):(<CurriculumCards courses={courses}/> )}    
        </div>
        </StyledEngineProvider>
    )
}
