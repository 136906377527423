import React, { useState} from 'react'

import '../../assets/styles.scss';

import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import validator from 'validator'
import moment from 'moment';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useEffect } from 'react';
  



const grades = [
    {
        value: '1',
        label: '1st',
    },
    {
        value: '2',
        label: '2nd',
    },
    {
        value: '3',
        label: '3rd',
    },
    {
        value: '4',
        label: '4th',
    },
    {
        value: '5',
        label: '5th',
    },
    {
        value: '6',
        label: '6th',
    },
    {
        value: '7',
        label: '7th',
    },
    {
        value: '8',
        label: '8th',
    },
    {
        value: '9',
        label: '9th',
    },
    {
        value: '10',
        label: '10th',
    },
    {
        value: '11',
        label: '11th',
    },
    {
        value: '12',
        label: '12th',
    },
];

const useStyles = makeStyles((theme) => {
    return {

    

      
        root: {
            
            "& .MuiOutlinedInput-root": {
                background: theme.palette.primary.white
            },
            marginTop: '1rem',
            marginBottom: '1rem',
            [theme.breakpoints.down('md')]: {
                marginBottom: '.5rem',
            },
            "& .MuiFormLabel-root": {
                color: "rgb(145, 158, 171)"
            },
            "& .Mui-focused": {
                color: theme.palette.text.primary
            }
        },
        submitButton: {
            padding: '10px 16px',
            width:'30%',
            [theme.breakpoints.down('sm')]: {
                width:'100%'
            }

        },
      
        schedule: {
            fontSize: '16px',
            textAlign: 'left',
            marginBottom: '1rem',
        },
        datepicker: {
            width: '100%',
            padding: '16.5px 14px',
            borderRadius: '4px',
            border: '1px solid #b6b6b6'
        },
        

    };
});


export default function RegistrationForm({courseList,bookCourse,countryList}) {

    const classes = useStyles();
    const [selectDate, setSelectDate] = useState();
    const [selectTime, setSelectTime] = useState();
    const [minDate,setminDate] = useState('initial');
    const [maxDate,setmaxDate] = useState('initial');
    const [course, setCourse] = useState(bookCourse);
    const [gradeData, setGradedata] = useState("1");
    const [countryData, setCountryData] = useState("");
    const [submit, setSubmit] = useState(false)
    const [alertMsg, setalertMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [formData, setformData] = useState({
        name: "",
        email: "",
        phone: "",
        country: countryData,
        grade: gradeData,
        courseid: course,
        appdate: "",
        apptime: ""
    })



    const { name, email, phone, country, grade, courseid, appdate, apptime } = formData;
    
    const handleName = (e) => {
        setformData({ ...formData, name: e.target.value })

    }
    const handleEmail = (e) => {
        setformData({ ...formData, email: e.target.value })
    }
    const handlePhone = (e) => {
        setformData({ ...formData, phone: e.target.value })
    }
    const handleCountry = (e) => {
        setformData({ ...formData, country: e.target.value })
        setCountryData(e.target.value)
    }
    const handleGrade = (event) => {
        setformData({ ...formData, grade: event.target.value })

        setGradedata(event.target.value);
    };
    const handleCourse = (event) => {
        setformData({ ...formData, courseid: event.target.value })
        setCourse(event.target.value);
    };


    const handleDate = (value) => {

        var date = moment(value).format("DD/MM/YYYY")
        setSelectDate(value)
        setformData({ ...formData, appdate: date })

    }

    const handleTime = (value) => {
        var time = moment(value).format("LT")
        setSelectTime(value)
        setformData({ ...formData, apptime: time })

    }

    useEffect(()=>{
        if(countryData === "India"){
           setminDate(setHours(setMinutes(new Date(),0),10))
           setmaxDate(setHours(setMinutes(new Date(),0),21))
       } else {
        setminDate(setHours(setMinutes(new Date(),0),6))
        setmaxDate(setHours(setMinutes(new Date(),0),22))
       }
    },[countryData])

    const handleSubmit = ((e) => {

        e.preventDefault()
        setLoading(true)

 



        if (!formData.name || !formData.email || !formData.country || !formData.grade || !formData.courseid || !formData.appdate || !formData.apptime) {
            setStatus("error")
            setalertMsg("Please fill all input fields")
            setSubmit(true)
            setLoading(false)

        } else {

            if (validator.isEmail(formData.email)) {

              

                axios.post("https://api.holbos.com/course-registration", { user_name: name, email_id: email, phone_no: phone, country: country, grade: grade, course_package_id: courseid,  appointment_date: appdate, appointment_time: apptime })
                    .then((res) => {

                        if (res.status === 200) {
                            if (res.data.status) {
                                setStatus("success")
                                setSubmit(true)
                              
                                setalertMsg(res.data.data)
                                setformData({
                                    name: "",
                                    email: "",
                                    phone: "",
                                    appdate: "",
                                    apptime: ""

                                })
                                setCountryData("")
                                setGradedata("")
                                setCourse("")
                                setLoading(false)
                                

                            } else {
                                setStatus("error")
                                setSubmit(true)
                                setalertMsg(res.data.data)
                                setLoading(false)

                            }
                        } else {
                            setStatus("error")
                            setSubmit(true)
                            setalertMsg("Unable to reach server")
                            setLoading(false)

                        }

                    })
                    .catch((error) => {
                        setStatus("error")
                        setalertMsg("Unable to reach server")
                        setSubmit(true)
                        setLoading(false)

                    })
            } else {

                setStatus("error")
                setalertMsg("Please Enter Valid Email Address")
                setSubmit(true)
                setLoading(false)

            }

        }
        //setTimeout(() => setSubmit(false), 10000);

    })

    return (
        <div>
            <StyledEngineProvider injectFirst>
              

                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}>
                                <TextField id="outlined-basic" label="Name" variant="outlined" className={classes.root} value={formData.name} fullWidth required onChange={handleName} />
                                <TextField id="outlined-basic" label="Email" variant="outlined" className={classes.root} value={formData.email} fullWidth required onChange={handleEmail} />
                                <TextField id="outlined-basic" type="number" label="Phone Number" variant="outlined" className={classes.root} value={formData.phone} fullWidth onChange={handlePhone} />
                                {/* <TextField id="outlined-basic" label="Country" variant="outlined" className={classes.root} fullWidth required onChange={handleCountry} /> */}
                                <TextField
                                    id="outlined-basic"
                                    select
                                    label="Select Country"
                                    value={countryData}
                                    onChange={handleCountry}
                                    helperText="Please select your country"
                                    className={classes.root} fullWidth required>
                                    {countryList && countryList.map((item,i) => (
                                        <MenuItem key={i} value={item.country}>
                                            {item.country}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-grade"
                                    select
                                    label="Select Grade"
                                    value={gradeData}
                                    onChange={handleGrade}
                                    helperText="Please select your grade"
                                    className={classes.root} fullWidth required>
                                    {grades.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="outlined-select-course"
                                    select
                                    label="Select Course"
                                    value={course}
                                    onChange={handleCourse}
                                    helperText="Please select your course"
                                    className={classes.root} fullWidth required>
                                    {courseList && courseList.map((item, i) => (
                                        <MenuItem key={i} value={item.course_package_id}>
                                            {item.course_package_title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                        
                                <Box sx={{marginTop:{xs:'10px',lg:'20px'}}}>
                                    <Typography className={classes.schedule}>Select an appointment for free-trial (Timezone: IST - UTC +5:30)</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>

                                            <DatePicker
                                                selected={selectDate}
                                                onChange={(value) => handleDate(value)}
                                                dateFormat="dd/MM/yyyy"
                                                className={classes.datepicker}
                                                placeholderText="Click to select a date"
                                                minDate={new Date()}
                                                value={formData.appdate}
                                            />


                                        </Grid>
                                        <Grid item xs={12} md={6}>


                                            <DatePicker
                                                selected={selectTime}
                                                onChange={(value) => handleTime(value)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={30}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                className={classes.datepicker}
                                                placeholderText="Click to select a Time"
                                                minTime={minDate}
                                                maxTime={maxDate}
                                                value={formData.apptime}
                                            />


                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '2.5rem', marginBottom: '2rem' }}>

                                    <Button type="submit" variant="contained" className={classes.submitButton} >
                                         Register
                                    </Button>
                                </Box>
                                {submit ? <Alert severity={status} sx={{ marginBottom: '20px' }}>{alertMsg}</Alert> : ""}
                                {loading ? <CircularProgress size={40} color="inherit" thickness={1} className={classes.loader} /> : ""}
                            </Box>


                    


            </StyledEngineProvider >
        </div>
    )
}
