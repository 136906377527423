import React, { useState, useEffect } from 'react'
import InnerBanner from "../Components/Common/InnerBanner";
import '../assets/styles.scss';
import banner from "../assets/Registaration.svg"
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import RegistrationForm from '../Components/Contact/RegistrationForm';
import { Helmet } from 'react-helmet-async';
//import { useParams} from "react-router-dom";
 // import { useSearchParams } from "react-router-dom";
  import { useLocation } from 'react-router-dom';
 

const useStyles = makeStyles((theme) => {
    return {

        curriculum: {
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column'
        },

        title: {
            textAlign: 'center',
            marginBottom: '30px',
            [theme.breakpoints.down('md')]: {
                marginBottom: '15px',
            },
        },
       
        
        formContainer: {
            backgroundColor: '#fff',
            borderRadius: '4px',
            boxShadow: 'rgb(248 239 255 / 24%) 0px 0px 2px 0px, rgb(248 239 255 / 24%) 0px 16px 32px -4px',
            padding: '20px'
        },
      
        loader: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
      
          }

    };
});

export default function Registration() {

    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [courseList, setCourseList] = useState(false)
    const [countryList,setCountryList] = useState("")
    //let { id } = useParams();
    const location = useLocation();

    const queryString = location.search; // Returns:'?q=123' 
    //console.log(queryString); 
    var id = queryString.match(/(\d+)/);
    //console.log(id[0]); 

    const fetchCountry = async() =>{
        const {data} = await axios.get(`https://api.holbos.com/country-list`);
       
        setCountryList(data.data)

       
    }

    useEffect(() => {
        
        setLoading(true)
        const fetchCourseList = async () => {
          const { data } = await axios.get(`https://api.holbos.com/course-package?fields=course_package_id%2Ccourse_package_title`);
          setCourseList(data.data)
          setLoading(false)
        }

        fetchCourseList()
        fetchCountry()
    
      }, [])








    return (
        <StyledEngineProvider injectFirst>
             <Helmet>
                <title>Holbos - Registration</title>
                <meta name="robots" content="noindex nofollow"></meta>
             </Helmet>
            <div className={classes.curriculum}>
                <InnerBanner title={"Register Your Course"} image={banner} />

                <Container maxWidth="md" sx={{ marginTop: { xs: '5px', md: '20px' }, marginBottom: { xs: '10px', md: '20px' }, paddingTop: { xs: '5px', md: '30px' }, paddingBottom: { xs: '20px', md: '30px' } }} >
                    <Box className={classes.formContainer}>


                        <Typography variant="h1" className={classes.title} sx={{ fontSize: { xs: '20px', md: '28px' } , fontWeight:{xs:'500'} }}>
                            Fill out the form carefully for registration
                        </Typography>
                        {loading ? (<Box className={classes.loader}><CircularProgress size={100} color="inherit" thickness={1} /></Box>) : (<RegistrationForm courseList={courseList} bookCourse={id?id[0]:"1"} countryList={countryList}/>)}

                    </Box>
                </Container>


            </div>
        </StyledEngineProvider >
    )
}
