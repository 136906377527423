
import { React, useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import CurriculumDetailHeader from '../Components/CurriculumDetail/CurriculumDetailHeader'
import CurriculumDetails from '../Components/CurriculumDetail/CurriculumDetails'
import { Helmet } from 'react-helmet-async';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import {
    useLocation
  } from "react-router-dom";
// import {
//     useParams
//   } from "react-router-dom";
import axios from "axios";
const useStyles = makeStyles((theme) => {
    return {

        curriculumdetail: {
            backgroundColor: theme.palette.primary.light,
            display:'flex',
            flexDirection:'column'
           
            },
        }
    })
export default function CourseDetail() {
    const classes = useStyles();
    const [course, setCourse] = useState("");
    const [coursePackage, setCoursePackage] = useState("");


    //let { id  } = useParams();
    const location = useLocation()
    let pageUrl = location.pathname.split('/')[2];
 

    useEffect(() => { 

        const fetchCourse = async () => {
            const { data } = await axios.get(`https://api.holbos.com/course?course_url_path=${pageUrl}`);
            setCourse(data.data[0])

        }
        fetchCourse()
        const fetchCoursePackage = async () => {
            const { data } = await axios.get(`https://api.holbos.com/course-package?course_url_path=${pageUrl}`);
            setCoursePackage(data.data)
          

        }
        fetchCoursePackage()

    }, [pageUrl])
    return (
        <StyledEngineProvider injectFirst>
        <Helmet>
        <title>{course.course_metadata_title}</title>
        <meta name='description' content={course.course_metadata_desc} />
      </Helmet>
        <div className={classes.curriculumdetail}>
            <CurriculumDetailHeader course={course}/>
            <CurriculumDetails course={course} coursepackage={coursePackage}/>
        </div>
        </StyledEngineProvider>
       
    )
}
