import React from 'react'
import "../assets/styles.scss"
export default function NotFound() {
    return (
        <div className='notfound_container'>
            <h1 className="title_text">Page Not Found</h1>
           
           <div className="error_container">
			
             <img className="error_bg" src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" alt="four_zero_four_bg"/>
		
		   </div>

           <div className="button_container">
              <a className="back_button" href="/">Go Back to Home Page</a>
           </div>

           
           
        </div>
    
    )
}
