import React from 'react'
import Container from '@mui/material/Container'
import FeedbackCard from './FeedbackCard';
import Grid from "@mui/material/Grid";

export default function TestimonialCards({review}) {


  return (
    <Container maxWidth="lg" sx={{
      paddingLeft: { xs: "25px", md: "15px" },
      paddingRight: { xs: "25px", md: "15px" }
    }}>
      <Grid container spacing={3} >

        {
          review && review.map((item, i) => (

            <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
             <FeedbackCard key={i} data={item} />
            </Grid>

          ))
        }



      </Grid>
    </Container>
  )
}
