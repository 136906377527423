import { React, useState , useEffect } from 'react'
import { makeStyles } from "@mui/styles";
//import InnerBanner from "../Components/Common/InnerBanner";
import '../assets/styles.scss';
//import banner from "../assets/cirriculum_banner.svg"
import CurriculumHeader from '../Components/Curriculum/CurriculumHeader';
import TestimonialCards from '../Components/TestimonialCards/TestimonialCards';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { Helmet } from 'react-helmet-async';
const useStyles = makeStyles((theme) => {
    return {

        curriculum: {
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom:'35px',
            [theme.breakpoints.down('md')]: {
                paddingBottom:'20px',
              },  

        },
        loader:{
            width:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            
        },
        testimonial_bg:{
          backgroundImage:'url("../Images/testimonial.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize:'cover',
          height:'250px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          [theme.breakpoints.down('md')]: {
            backgroundImage:'url("../Images/testimonial_900.svg")',
          },
          [theme.breakpoints.down('sm')]: {
            backgroundImage:'none',
            //height:'150px',
          },  

        }
      
    }
})



export default function Course() {
    const classes = useStyles()
    const [review, setReview] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => { 
        setLoading(true)
        const fetchReview = async () => {
            const { data } = await axios.get(`https://api.holbos.com/user-testimonial`);
                setReview(data.data)
                setLoading(false)
             }
        fetchReview()

    }, [])
    
    return (
      <StyledEngineProvider injectFirst>
         <Helmet>
            <title>Holbos - Testimonials</title>
          </Helmet>
        <div className={classes.curriculum}>
            {/* <InnerBanner title={"Our Top-Class Courses"} image={banner} /> */}
            <Box className={classes.testimonial_bg}>
              <CurriculumHeader title={"Happy notes from our satisfied users"} desc={"Little chunks of happiness shared from vibrant parents and their kids are reflected here"} />
            </Box>
           
            {loading?(<Box className={classes.loader}><CircularProgress size={100} color="inherit" thickness={1} /></Box>):(<TestimonialCards review={review}/> )}    
     
        </div>
        </StyledEngineProvider>
    )
}
