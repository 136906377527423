import React from 'react'
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
//import Link from '@mui/material/Link';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import '../../assets/styles.scss'
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => {
  return {

    pricingcard: {
      borderRadius: '20px',
      boxShadow: '0 18px 36px 0 rgb(213 215 225 / 49%)',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'

    },

    package_details: {
      padding: '17px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    },

    package_title: {
      fontSize: '1.2rem',
      textAlign: 'left',
      marginBottom: '16px'
    },

    coursedesc: {
      fontSize: '1rem',
      textAlign: 'left',
      fontFamily: 'Roboto',
      color: '#343D48',
      marginBottom: '1.25rem'
    },
    course_highlights_title: {
      fontSize: '1.2rem',
      textAlign: 'left',
      marginBottom: '1.25rem',
      fontWeight: '500'
    },

    courseFeatureList: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      margin: '0px 0px 20px 0px',
      padding: '0'
    },
    courseFeatureListItem: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '0.9rem',
      alignItems: 'center',
      color: theme.palette.text.color1,
      marginBottom: '10px',
      width: '100%',
    },
    icon: {
      width: '28px',
      height: '28px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: '0'
    },
    courseFeature: {
      margin: '0px 0px 0px 12px'
    },
    bookButton: {
      textAlign: 'center',
      backgroundColor: '#164978'
    }
  }
})
export default function PackageCards({ coursepackage }) {

  const classes = useStyles();
  const navigate = useNavigate();
  
  return (
    <>
      {
        coursepackage && coursepackage.map((item, i) => (
          <Grid item xs={12} md={6} lg={4} className="package_1" key={i}>
            <Paper elevation={1} className={classes.pricingcard}>
              <Box className="package_plan">
                {item.course_package_type}
              </Box>
              <Box className={classes.package_details}>
                <Box>
                  <Box >
                    <Typography variant="h6" className={classes.package_title}>{item.course_package_title}</Typography>
                  </Box>
                  <Box className={classes.coursedesc}>
                    <Typography variant="p">{item.course_package_description}</Typography>
                  </Box>
                  <Box className={classes.course_highlights_title}>
                    <Typography variant="p"  >
                      What you will Learn
                    </Typography>
                  </Box>
                  <Box>
                    <ul className={classes.courseFeatureList}>
                      {
                        item && item.course_package_highlights.map((value, i) => (
                          <li className={classes.courseFeatureListItem} key={i}>
                            <div className={classes.icon} >
                              <CheckIcon color="primary" sx={{ width: '20px', height: '20px' }} />
                            </div>
                            <Typography className={classes.courseFeature}>{value} </Typography>
                          </li>
                        ))
                      }

                    </ul>
                  </Box>
                </Box>

                <Box >
                  {/* <Button component={Link} href={`/registration/${item.course_package_id}`} variant="contained" color="primary" fullWidth sx={{ marginBottom: '1rem' }}>Book Now</Button> */}
                    <Button component={Button} onClick={()=>navigate({ pathname:'/registration',search:`id:${item.course_package_id}`})}variant="contained" color="primary" fullWidth sx={{ marginBottom: '1rem' }}>Book Now</Button>

                </Box>
              </Box>

            </Paper>
          </Grid>
        ))
      }
    </>
  )
}
