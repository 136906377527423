import Link from '@mui/material/Link';
import React from 'react'
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
    return {
  
   
  
      link: {
        fontFamily: "Inter",
        textDecoration: "none",
        marginRight: theme.spacing(4),
        color: theme.palette.text.primary,
        textTransform: "none",
        fontSize: "14px",
        fontWeight: '500',
        border: "1px solid transparent",
        padding: theme.spacing(1),
        borderRadius: "4px",
        transition: "all 0.5s ease",
        "&:hover": {
          color: theme.palette.primary.main,
          border: "1px solid #8d448b",
        },
  
      },
      active: {
        color: theme.palette.primary.main,
        border: "1px solid #8d448b",
      },
   
    };
  });

export default function BlogLink() {

 const classes = useStyles();
  return (
      <Link className={classes.link} href="https://blog.holbos.com" underline="none" onClick={(e) => { e.preventDefault(); window.open('https://blog.holbos.com', '_blank'); }}>
       Blogs
      </Link>
  )
}
