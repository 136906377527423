import React from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Container from '@mui/material/Container'
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => {
    return {

        curriculum: {

            margin: '20px auto 10px',
            [theme.breakpoints.down('lg')]: {
                
                padding: '0px 15px'
            },
        },

        curriculumbanner: {
            backgroundColor: theme.palette.primary.white,
            boxShadow: '0 18px 36px 0 rgb(213 215 225 / 49%)',
            borderRadius: '8px',
            padding: '20px',
            [theme.breakpoints.down('md')]: {
                padding: '20px 20px'
            },
        },

        course_title: {
            marginBottom: '5px'
        },
        course_detail: {
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '15px'
            },
        },

        category: {
            width: 'max-content',
            fontSize: '14px',
            borderRadius: '4px',
            fontWeight: '500',
            color: theme.palette.primary.main,
            padding: '5px 8px',
            backgroundColor: theme.palette.primary.light,
            marginBottom:'10px'

        },
        headerButton: {
            textTransform: "none",
            boxShadow: 'rgb(141 68 139 / 24%) 0px 8px 16px 0px'
          },
          wordFormat:{
            whiteSpace:"pre-wrap",
            wordBreak: "break-word"
          }

    };
});
export default function CurriculumDetailHeader({course}) {
    const classes = useStyles();
    return (
        <StyledEngineProvider injectFirst>



            <Container maxWidth="lg" className={classes.curriculum}  >

                <div className={classes.curriculumbanner} >
                    <Grid container
                        spacing={3}>

                        <Grid item xs={12} md={4} lg={3}>
                            <Box sx={{ textAlign: 'center' }}>
                               {course.course_image_url?(<img src={course.course_image_url} alt={course.course_title} className={classes.course_img} />):( <Skeleton variant="rectangular" width={210} height={118} />)} 
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                            <Box sx={{ textAlign: { xs: 'left' } }}>

                                {
                                    course.course_title?(

                                        <Typography variant="h1" className={classes.course_title} sx={{ fontSize: { xs: '20px', sm: '24px'} , fontWeight:{xs:'500'} }}>

                                        {course.course_title}
    
                                        </Typography>

                                    ):(
                                        <Skeleton width="30%" />
                                    )
                                }

                                {
                                   course.course_description?(

                                    <Box variant="h6" className={classes.course_detail} sx={{ fontSize: { xs: '16px' }, fontWeight: 'regular' }}>
                                    <pre className={classes.wordFormat}>
                                    {course.course_description}
                                    </pre>
                                    </Box>

                                   ) : (

                                    <Skeleton/>
                                   

                                   )
                                }
                               
                                <Typography className={classes.category}>
                                      {course.tag}
                                </Typography>
                              

                            </Box>
                        </Grid>

                    </Grid>
                </div>




            </Container>

        </StyledEngineProvider>
    )
}
