import React  from "react";
import '../assets/styles.scss'
import CircularProgress from '@mui/material/CircularProgress';



export default function Loader() {
  return (
      <div  className="pageLoader">
        <CircularProgress size={100} color="inherit" thickness={1}/>
      </div>
    
  );
}
