import { React, useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import InnerBanner from "../Components/Common/InnerBanner";
import '../assets/styles.scss';
import banner from "../assets/cirriculum_banner.svg"
import CurriculumHeader from '../Components/Curriculum/CurriculumHeader';
import CurriculumCards from '../Components/Curriculum/CurriculumCards';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => {
    return {

        curriculum: {
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom:'35px',
            [theme.breakpoints.down('md')]: {
                paddingBottom:'20px',
              },  

        },
        loader:{
            width:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            
        }
      
    }
})



export default function AllCourses() {
    const classes = useStyles()
    const [courses, setCourses] = useState("");
    const [loading, setLoading] = useState(false);



    useEffect(() => { 
        setLoading(true)
        const fetchCourses = async () => {
            const { data } = await axios.get(`https://api.holbos.com/course`);
                setCourses(data.data)
                setLoading(false)
             }
        fetchCourses()

    }, [])
    
    return (
        <StyledEngineProvider injectFirst>
            <Helmet>
            <title>Holbos - Courses</title>
          </Helmet>
        <div className={classes.curriculum}>
            <InnerBanner title={"Our Top-Class Courses"} image={banner} />
            <CurriculumHeader title={"Welcome to Holbos course gallery"} desc={"The category of courses listed below will transform your child to a champion.The Journey of endless knowledge begins from now on. We promise to give our finest service based on your requirements. Let’s together build your child’s future with Holbos courses."} />
            {loading?(<Box className={classes.loader}><CircularProgress size={100} color="inherit" thickness={1} /></Box>):(<CurriculumCards courses={courses}/> )}    
        </div>
        </StyledEngineProvider>
    )
}
