import React from "react";
//import Grid from "@mui/material/Grid";
//import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./FeedbackCard.css";

// const useStyles = makeStyles((theme) => {
//   return {

//   };
// });

export default function FeedbackCard({ data }) {
  //const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <Card className="pricingcard">
        <CardContent className="card" sx={{ padding: "15px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className="profile_wrapper" sx={{ display: "flex" }}>
              <img
                src={data.image_url}
                alt="Parent Profile"
                className="profile"
              />
            </Box>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              fontWeight="400"
              className="username"
            >
              {data.username}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              fontWeight="400"
              className="user_type"
            >
              {data.user_type}
            </Typography>
          </Box>

          <Typography
            gutterBottom
            variant="p"
            component="div"
            fontWeight="400"
            className="feedback"
          >
            <pre className="word_format">
            {data.testimonial_desc}
            </pre>
          </Typography>
        </CardContent>
      </Card>
    </StyledEngineProvider>
  );
}
