import React from 'react'
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import DrawerComponent from "./Drawer"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate , useLocation } from 'react-router-dom'
import clsx from 'clsx';
import logo from "../../assets/logo.png";
import BlogLink from "../Common/BlogLink";

const useStyles = makeStyles((theme) => {
  return {

    navitem: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center'
    },

    logowrap: {
      flexGrow: 1,
    },
    logo: {
      width: "150px",
      height: "auto",
    },

    link: {
      fontFamily: "Inter",
      textDecoration: "none",
      marginRight: theme.spacing(4),
      color: theme.palette.text.primary,
      textTransform: "none",
      fontSize: "14px",
      fontWeight: '500',
      border: "1px solid transparent",
      padding: theme.spacing(1),
      borderRadius: "4px",
      transition: "all 0.5s ease",
      "&:hover": {
        color: theme.palette.primary.main,
        border: "1px solid #8d448b",
      },

    },
    active: {
      color: theme.palette.primary.main,
      border: "1px solid #8d448b",
    },
    headerButton: {
      textTransform: "none",
      boxShadow: 'rgb(141 68 139 / 24%) 0px 8px 16px 0px'
    },
  };
});

export default function NavItems() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const menuItems = [
    {
      text: 'Home',
      path: '/'
    },
    {
      text: 'About Us',
      path: '/about'
    },
    {
      text: 'Courses',
      path: '/courses'
    },
    {
      text: 'Testimonials',
      path: '/testimonials'
    },
     
    {
      text: 'Contact',
      path: '/contact'
    }
  ]

  return (
    <div className={classes.navitem}>
      <Box className={classes.logowrap}>
      <Link to="/" >
        <img src={logo} alt="Holbos Logo" className={classes.logo} />
      </Link>
      </Box>
     
      {isMobile ? (<DrawerComponent />) : (
        <Box className={classes.navlinks} >
          {menuItems.map((item, index) => (
            <Link reloadDocument  key={index} to={item.path} className={location.pathname === item.path ? clsx(classes.link, classes.active) : (classes.link)}>{item.text}</Link>
          ))}
          <BlogLink/>
          
          <Button variant="contained" className={classes.headerButton} onClick={() => navigate("/registration")}>
            Book a Free Trial
          </Button>
        </Box>)}

    </div>
  )
}
