import React from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
//import TwitterIcon from '@mui/icons-material/Twitter';
import './Followus.css';
const useStyles = makeStyles((theme) => {
  return {
    title:{
      textAlign:'center'
    },
    social_links: {
      display: 'flex',
      padding:0
    },

 
  };
});
export default function FooterSocial() {

  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>

      <Box sx={{ marginTop: { xs: '10px' } }}>
        <Typography variant="h4" color="text.primary" className={classes.title} sx={{ fontSize: { xs: '18px', md: '20px', lg: '24px' },marginBottom:'15px', fontWeight: '600', textAlign: { xs: 'center', md: 'left' } }}>
          Follow Us On
        </Typography>
        <ul className={classes.social_links}>
          <li className="social_links_item">
            <Link href="https://www.facebook.com/holbosteam/" className="social_icon fb" target="_blank">
              <FacebookIcon sx={{ fontSize: {xs:'24px',sm:'32px'} }}  />
            </Link>
          </li>
          <li className="social_links_item">
            <Link href="https://www.linkedin.com/company/holbos/" className="social_icon ln" target="_blank">
              <LinkedInIcon  sx={{ fontSize: {xs:'24px',sm:'32px'} }}/>
            </Link>
          </li>
          <li className="social_links_item">
            <Link href="https://www.instagram.com/holbo.s/" className="social_icon inst" target="_blank">
              <InstagramIcon sx={{ fontSize: {xs:'24px',sm:'32px'} }}  />
            </Link>
          </li>
          {/* <li className="social_links_item">
            <Link href="#" className="social_icon tw" target="_blank">
            <TwitterIcon sx={{ fontSize: 28 }}  />
            </Link>
          </li> */}

        </ul>
      </Box>
    </StyledEngineProvider>
  )
}
