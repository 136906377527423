import React,{ Suspense } from "react";
import HomeSlider from "../Components/Carousel/HomeSlider";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import '../assets/styles.scss'
import Loader from "../Components/Loader.js"
// const About = React.lazy(() => import('../Components/About/About'));
const PopularDomains  = React.lazy(() => import('../Components/Domains/PopularDomains'));
const QualityFeature = React.lazy(() => import('../Components/Features/QualityFeature'));
const Main = React.lazy(()=>import('../Components/About/Main'));
const MainSection2 = React.lazy(()=>import('../Components/About/MainSection_2'));
const ChooseUs = React.lazy(()=>import('../Components/About/ChooseUs'));
const DoTogether = React.lazy(()=>import('../Components/About/DoTogether'));
const MainSection3 = React.lazy(()=>import('../Components/About/MainSection_3'));
//import QualityFeature from "../Components/Features/QualityFeature";
//import PopularDomains from "../Components/Domains/PopularDomains";
//import About from "../Components/About/About";
const UniversityLogo = React.lazy(() => import('../Components/Common/UniversityLogo'));


export default function Home() {
  return (
    <StyledEngineProvider injectFirst>
    <div>
    <Suspense fallback={<Loader/>} >
     <HomeSlider/>
     {/* <About/> */}
     <Main/>
     <MainSection2/>
     <MainSection3/>
     <ChooseUs/>
     <DoTogether/>
     <PopularDomains/>
     <QualityFeature/>
     </Suspense>
    </div>
    </StyledEngineProvider>
  );
}
