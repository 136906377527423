import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import logo from "../../assets/logo.png";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Subscribe from "./Subscribe"
import FooterSocial from "./FooterSocial"
const useStyles = makeStyles((theme) => {
  return {
    footer_bg: {
      backgroundColor: theme.palette.third.main,
    },
    footer_logo: {
      width: "150px",
      marginBottom: "10px",
    },
    footer_desc: {
      fontSize: "14px",
      fontWeight: '500',
      color: theme.palette.text.grey,
      [theme.breakpoints.down('md')]: {
        fontSize: "14px"
      }

    },

    footer_section: {
      display: "flex",

    },
    section_1: {
      display: "flex",
      flexDirection: "column",
    },
    footer_header: {
      fontWeight: 600,
      fontSize: "14px",
      margin: "0 0 20px 0",
    },
    footer_item: {

      fontWeight: 500,
      fontSize: "14px",
      width: 'fit-content',
      color: theme.palette.text.grey,
      textDecoration: "none",
      margin: "0 0 20px 0",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    subscribe: {
      display: "flex",
    },
    subscribe_field: {
      marginRight: "15px",
      backgroundColor: theme.palette.primary.white,
      borderColor: theme.palette.primary.main,
      width: "100%",
    },
    subscribe_btn: {
      height: "100%",
      minHeight: "45px",
    },
    copyright: {

      fontWeight: 400,
      fontSize: "14px",
      marginTop: "20px",
      display: "block",
    },
  };
});
export default function Footer() {
  const classes = useStyles();
  const footerMenu = [
    { link: "Home", path: "/" },
    { link: "About Us", path: "/about" },
    { link: "Courses", path: "/courses" },
    { link: "Contact", path: "/contact" },
    { link: "Testimonials", path: "/testimonials" },
    { link: "Book a Free Trial", path: "/registration" },
    { link: "Blogs", path: "https://blog.holbos.com" },
    { link: "Terms & Conditions", path: "/termsandconditions" },
    { link: "Privacy Policy", path: "/privacypolicy" },
  ];
  return (
    <StyledEngineProvider injectFirst>
      <footer className={classes.footer_bg}>
        <Divider />
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: { xs: "20px", md: "30px", lg: "50px" },
            paddingBottom: { xs: "20px", md: "30px", lg: "50px" },
          }}
        >
          <Grid container spacing={{xs:2, lg:5}}>
            <Grid item md={12} lg={4}>
              <Box>
                <img
                  src={logo}
                  alt="Holbos logo"
                  className={classes.footer_logo}
                />
              </Box>
              <Typography
                variant="body"
                color="initial"
                className={classes.footer_desc}
              >
                 Learn from experts not a general curriculum for all, but a personalised curriculum only for you

              </Typography>
              <FooterSocial/>
            </Grid>
            <Grid item md={12} lg={8}>
              <Grid container
                className={classes.footer_section}
                sx={{ flexDirection: { xs: "column", md: "row" },justifyContent:'space-evenly' }}
              >
                <Grid item xs={12} md={3} className={classes.section_1}>
                  <Typography
                    variant="p"
                    color="text"
                    className={classes.footer_header}
                  >
                    QUICK LINKS
                  </Typography>
                  {footerMenu.map((item, index) => (
                    <Link
                      key={index}
                      href={item.path}
                      className={classes.footer_item}
                    >
                      {item.link}
                    </Link>
                  ))}
                </Grid>
                <Grid item xs={12} md={3} className={classes.section_1}>
                  <Typography
                    variant="p"
                    color="text"
                    className={classes.footer_header}
                  >
                    CONTACT US
                  </Typography>
                  <Link href="https://maps.app.goo.gl/qvTTD1bChh3RFtm78" className={classes.footer_item}>
                  867 Boylston Street<br/>
                  5th Floor #1699<br/>
                  Boston, MA 02116<br/>
                  USA
                  </Link>
                  <Link
                    href="mailto:info@holbos.com"
                    className={classes.footer_item}
                  >
                    info@holbos.com
                  </Link>
                  <Link
                    href="mailto:holbosofficial@gmail.com"
                    className={classes.footer_item}
                  >
                   holbosofficial@gmail.com
                  </Link>
                  <Link href="tel:+91 7994121112" className={classes.footer_item}>
                    +91 7994121112
                  </Link>
                </Grid>
                <Grid item xs={12} md={4} className={classes.section_1}>
                  <Typography
                    variant="p"
                    color="text"
                    className={classes.footer_header}
                  >
                    EMAIL SUBSCRIPTION
                  </Typography>

                  <Subscribe />



                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Typography variant="p" color="text" className={classes.copyright}>
              © {new Date().getFullYear()} Holbos. All rights reserved
            </Typography>
          </Box>
        </Container>
      </footer>
    </StyledEngineProvider>
  );
}
