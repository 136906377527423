import {React,useEffect,useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Questions from '../Components/Quiz/Questions';
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
    return {

        quizContainer:{
            backgroundColor:theme.palette.primary.light,
            display:'flex',
            flexDirection:'column',
            minHeight:'500px',
            alignItems:'center',
            justifyContent:'center'
        }

    };
});
export default function QuizMain({name,score,questions,setQuestions,setScore,course,email}) {
    const classes = useStyles();
    const [options,setOptions] = useState();
    const [currentQues,setCurrentQues] = useState(0);

    useEffect(()=>{
      setOptions(questions && handleShuffle([questions[currentQues]?.correct_answer,...questions[currentQues]?.incorrect_answers]) );
     
    },[questions,currentQues])
    


    const handleShuffle = (option)=>{
         return option.sort(()=>Math.random() - 0.5);
    }
 
    return (

        <StyledEngineProvider injectFirst>
        <div className={classes.quizContainer}>
           
        
           
            
            {
                questions?(
                <>
                <div className="quizHeader">
                    <div className="userName">Welcome {name}</div>
                    <div className="userDomain">Choosen Domain:{questions[currentQues].course_title}</div>
                </div>
           
               
                <Questions 
                 currentQues={currentQues}
                 setCurrentQues={setCurrentQues}
                 questions={questions}
                 options={options}
                 correct={questions[currentQues]?.correct_answer}
                 score={score}
                 setScore={setScore}
                 setQuestions={setQuestions}
                 name={name}
                 course={course}
                 email={email}
                />
                </>

                    
                    ):(<CircularProgress size={150} color="inherit" thickness={1} className="loader"/>)
            }
        </div>
        </StyledEngineProvider>
    )
}
