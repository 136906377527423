import { React } from "react";
import { makeStyles } from "@mui/styles";
//import TextContent from "../Components/Content/TextContent"
import QuickContact from "../Components/Contact/QuickContact"

// const PrivacyPolicy = [
//      {
//          title:"1.Why do we use it",
//          desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. "
//      },
//      {
//         title:"1.Why do we use it",
//         desc:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. "
//     },

//     ]

const useStyles = makeStyles((theme) => {
  return {
    curriculumdetail: {
      display: "flex",
      flexDirection: "column",
      padding: "15px",
    },
    pageTitleContainer: {
      padding: "30px 20px",
      textAlign: "center",
      backgroundColor: "#EDEEEE",
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px",
      },
    },
    pageTitle:{
      fontSize:'32px',
     [theme.breakpoints.down("md")]: {
       fontSize: "24px",
     },
    
   },
    pageTitledesc: {
      textAlign: "left",
      padding: "20px",
      width: "80%",
      margin: "0 auto",
      fontSize: "18px",
      paddingTop:"3rem",
      paddingBottom:"3rem", 
      [theme.breakpoints.down("md")]: {
        padding: "10px 10px",
        width: "100%",
        fontSize: "16px",
      },
    },
  };
});


export default function CourseDetail() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageTitleContainer}>
        <h1 className={classes.pageTitle}>Privacy Policy</h1>
      </div>
      <div className={classes.curriculumdetail}>
    

    <div className={classes.pageTitledesc}>
    Holbos cares about your privacy and wants you to be familiar with the information that we collect from your end for our business purposes. The following information is collected from users visiting our website. The user activities are recorded,contact information are taken with the concerns of users during demo trials.Email address of user is collected for receiving updates on their purchases and news about us. The above mentioned details are verified by our team and secured on our end for the smooth operations. We do share this information internally for communication purposes.
    </div>
 {/* {
     PrivacyPolicy.map((item)=>(
      <TextContent title={item.title} desc={item.desc}/>
     ))
 } */}
     
     <QuickContact title="Any question about our policy"/>

    </div>
    </>
   
      
  );
}
