import { BrowserRouter , Route,Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Contact from "./pages/Contact";
import Course from './pages/Course';
import AllCourses from './pages/AllCourses';
import Testimonials from './pages/Testimonials'
import BookCourse from "./pages/BookCourse";
import Home from "./pages/Home";
import About from "./pages/About";
import Layout from "./Components/Common/Layout";
import CourseDetail from "./pages/CourseDetail.js"
import Registration from "./pages/Registration.js"
import TermsandConditions from "./pages/TermsandConditions.js"
import PrivacyPolicy from "./pages/PrivacyPolicy.js"
import NotFound from "./pages/NotFound.js"
import Holbos from "./pages/Holbos.js";
function App() {
  
  return (
    <ThemeProvider theme={theme}>
      {/* <Routes forceRefresh={true}> */}
       
        <BrowserRouter forceRefresh={true}> 
        <Layout>
          <Routes >
            <Route exact path="/" element={ <Home />}/>
            <Route path="/about" element={ <About />}/ >
            <Route path="/course/*" element={<Course />}/>
            <Route path="/courses" element={<AllCourses />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/testimonials" element={<Testimonials />}/>
            <Route path="/courses/:coursename" element={  <CourseDetail />}/>
            <Route path="/registration" element={<Registration />}/>
            <Route path="/holbos/:id/:status/:message" element={<Holbos/>}/>
            <Route path="/privacypolicy" element={ <PrivacyPolicy />}/>
            <Route path="/termsandconditions" element={<TermsandConditions />}/>
            <Route path="/bookcourse/*" element={ <BookCourse />}/>
            <Route path="/404" element={ <NotFound/>}/>
          </Routes>
          </Layout>
          </BrowserRouter> 
  
      {/* </Routes> */}
    </ThemeProvider>
  );
}

export default App;
