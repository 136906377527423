import React from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
//import TwitterIcon from '@mui/icons-material/Twitter';
import './FooterSocial.css';
const useStyles = makeStyles((theme) => {
  return {
     social_links:{
        display:'flex',
        justifyContent:'flex-start',
        padding:0
     },
     social_links_item:{
       marginRight:'10px',
       background: 'transparent',
       borderRadius: '50%',
       padding: '10px',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       transition: 'background 0.5s ease-out',
       "&:hover": {
        background: '#fff',
      },
     },
     social_link_fb:{
       color:'#4267B2',
       display: 'flex',
     },
     social_link_ln:{
      color:'#00a0dc',
      display: 'flex',
     },
     social_link_tw:{
      color:'#1DA1F2',
      display: 'flex',
     }
  };
});
export default function FooterSocial() {

  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <Box sx={{ marginTop: { xs: '10px' } }}>
        <ul className={classes.social_links}>
          <li className={classes.social_links_item}>
            <Link href="https://www.facebook.com/holbosteam/" className={classes.social_link_fb} target="_blank">
              <FacebookIcon  style={{ fontSize: 32 }}/>
            </Link>
          </li>
          <li className={classes.social_links_item}>
            <Link href="https://www.linkedin.com/company/holbos/" className={classes.social_link_ln} target="_blank">
              <LinkedInIcon style={{ fontSize: 32 }}/>
            </Link>
          </li>
          <li className={classes.social_links_item}>
            <Link href="https://www.instagram.com/holbo.s/" className="social_link_inst" target="_blank">
              <InstagramIcon style={{ fontSize: 32 }} />
            </Link>
          </li>
          {/* <li className={classes.social_links_item}>
            <Link href="#" className={classes.social_link_tw} target="_blank">
              <TwitterIcon />
            </Link>
          </li> */}
        </ul>








      </Box>
    </StyledEngineProvider>
  )
}
