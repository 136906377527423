import React from 'react'
import { makeStyles } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Container from '@mui/material/Container'
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CourseAccordion from '../Common/CourseAccordion.js';
//import CoursePackage from './CoursePackage.js';
import Divider from '@mui/material/Divider';
import PackageCards from './PackageCards.js';
import Grid from '@mui/material/Grid';
const useStyles = makeStyles((theme) => {
    return {

        curriculum: {

            margin: '10px auto 20px',
            [theme.breakpoints.down('lg')]: {

                padding: '0px 15px'
            },
        },

        curriculumbanner: {
            backgroundColor: theme.palette.primary.white,
            boxShadow: '0 18px 36px 0 rgb(213 215 225 / 49%)',
            borderRadius: '8px',
            padding: '2rem 2rem',
            [theme.breakpoints.down('md')]: {
                padding: '20px 20px'
            },

        },


        course_title: {
            marginBottom: '20px',
            marginTop: '15px'
        },
        course_detail: {
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '15px'
            },
        },

        courseFeatureList: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '0px 0px 40px 0px',
            padding: '0'
        },
        courseFeatureListItem: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: theme.palette.text.color1,
            marginBottom: '20px',
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        icon: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: '0'
        },
        courseFeature: {
            margin: '0px 0px 0px 12px'
        },



    };
});

export default function CurriculumDetails({ course, coursepackage }) {
    const classes = useStyles();

    return (
        <StyledEngineProvider injectFirst>



            <Container maxWidth="lg" className={classes.curriculum}  >

                <div className={classes.curriculumbanner} >


                    {/* <CoursePackage coursepackage={coursepackage}/> */}
                    <Grid container spacing={2} className="package_container" sx={{display:'flex',justifyContent:'center'}}>


                        <PackageCards coursepackage={coursepackage} />


                    </Grid >

                    <Divider />

                    <Grid item xs={12} >
                        <Box sx={{ textAlign: { xs: 'left' } }}>
                            <Typography variant="h6" className={classes.course_title} sx={{ fontSize: { xs: '20px', sm: '24px' } }}>

                                FAQs
                            </Typography>
                            {

                                <CourseAccordion course={course} />

                            }


                        </Box>
                    </Grid>


                </div>




            </Container>

        </StyledEngineProvider>
    )
}
