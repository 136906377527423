import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ContactForm from "../Components/Contact/ContactForm";
import Box from "@mui/material/Box";
import ContactImage from "../assets/contact.png";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Followus from "../Components/Contact/Followus";
import { Helmet } from 'react-helmet-async';
const useStyles = makeStyles((theme) => {
  return {
    // root: {
    //     padding: '60px 0',
    //     [theme.breakpoints.down('md')]: {
    //         padding: '30px 15px',
    //     },
    // },
    contactPage: {
      backgroundColor: theme.palette.primary.light,
    },
    contact_image: {
      width: "30vw",
      [theme.breakpoints.down("md")]: {
        width: "60vw",
      },
    },
  };
});
export default function Contact() {
  const classes = useStyles();
  return (
    <StyledEngineProvider injectFirst>
      <Helmet>
        <title>Holbos - Contact Us</title>
      </Helmet>
      <div className={classes.contactPage}>
        <Container maxWidth="lg" className={classes.root}>
          <Grid
            container
            spacing={{ xs: 4, md: 6 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} md={6}>
              <ContactForm />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Followus />
              <Box>
                <img
                  src={ContactImage}
                  alt="contact holbos"
                  className={classes.contact_image}
                />
              </Box>
           
            </Grid>
          </Grid>
        </Container>
      </div>
    </StyledEngineProvider>
  );
}
